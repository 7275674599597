.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  overflow: hidden;
}
.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
}
.date {
  font-size: 14px;
  font-weight: 300;
}