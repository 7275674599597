.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.box {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 2.5fr 1fr;
  padding: 0 10px;
}