.box {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  background: white;
  width: 520px;
  z-index: 101;
  border-radius: 6px;
  box-sizing: border-box;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;
}
.title {
  font-size: 18px;
  font-weight: 500;
  user-select: none;
}
.close {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 22px;
  color: rgb(118, 118, 118);
  cursor: pointer;
}
.close:active {
  transform: scale(0.97);
}

.overlay {
  position: absolute;
  top: 0;
  background: black;
  width: 100vw;
  height: 100vh;
  opacity: 40%;
  z-index: 100;
}