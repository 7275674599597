.form {
  padding: 0 30px 30px 30px;
}

.input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  padding: 0 10px;
  border: 1px solid #d0d7de;
  transition: all ease 0.3s;
  margin-bottom: 15px;
}
.input:focus {
  outline: none;
  border: 1px solid #1976d2;
}

.lable {
  font-size: 13px;
  margin-bottom: 7px;
  color: rgb(92, 92, 92);
}
.bottom {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.btn {
  height: 40px;
  border: none;
  border-radius: 6px;
  background: #1976d2;
  cursor: pointer;
  color: white;
  transition: all ease 0.2s;
  font-weight: 500;
  user-select: none;
  padding: 0 35px;
}
.btn:hover {
  background: #5c75e4;
}