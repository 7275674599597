.wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 10px;
  position: relative;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  user-select: none;
  pointer-events: none;
}
.input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  padding: 0 10px;
  border: 1px solid #d0d7de;
  transition: all ease 0.3s;
}
.input:focus {
  outline: none;
  border: 1px solid #1976d2;
}

.btn {
  height: 40px;
  border: none;
  border-radius: 6px;
  background: #1976d2;
  cursor: pointer;
  color: white;
  transition: all ease 0.2s;
  font-weight: 500;
  user-select: none;
}
.btn:hover {
  background: #5c75e4;
}
.btn:disabled {
  background: #8a99dd;
  cursor: default;
}

.error {
  width: 100%;
  height: 40px;
  background: #fdeded;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 10px;
  border: 1px solid #ffe2e2;
  font-size: 14px;
  color: rgb(95, 33, 32);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  left: 0;
}