.box {
  margin-top: 40px;
  width: 350px;
}
.box__fixed {
  width: 350px;
  position: fixed;
  overflow: hidden;
  background: white;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 6px;
  overflow: hidden;
}
.link__box {
  width: 100%;
  height: 50px;
  border: 1px solid #227fdd;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(60, 60, 60);
  transition: all ease 0.3s;
  cursor: pointer;
  margin-bottom: 25px;
}
.link__box:hover {
  background: #fafbfc;
  color: #227fdd;
}
.link__icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #227fdd;
}
.destination {
  margin-bottom: 20px;
}
.destination__title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
}
.destination__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 5px;
}
.destination__text {
  width: 300px;
  overflow: hidden;
  font-size: 14px;
  word-wrap: break-word;
  color: rgb(70, 70, 70);
  transition: color ease 0.3s;
}
.destination__text:hover {
  color: rgb(3, 74, 180);
}

.hr__line {
  width: 320px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d0d7de;
  box-sizing: border-box;
}

.clicks {
  display: flex;
  justify-content: space-between;
  padding: 10px 35px;
  margin-bottom: 10px;
}
.click {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.click__number {
  font-size: 26px;
  font-weight: 700;
}
.click__subtitle {
  font-size: 13px;
}

.button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 0 5px;
  background: #1976d2;
  cursor: pointer;
  color: white;
  transition: all ease 0.2s;
  font-weight: 500;
  user-select: none;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background: #5c75e4;
}
.button:disabled {
  background: #8a99dd;
  cursor: default;
}
.buttons {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.two__buttons {
  display: flex;
  gap: 5px;
}
.delete {
  color: black;
  background: white;
  border: 1px solid #d0d7de;
  box-sizing: border-box;
}
.delete:hover {
  color: white;
  background: red;
  border: none;
}

.checkbox {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}

.textarea {
  position: absolute;
  top: -1000px;
  opacity: 0;
}