.box {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1.4fr 1.4fr 0.7fr 0.5fr;
  align-items: center;
  font-size: 14px;
  border: 1px solid #EBEBEB;
  border-top: none;
  background: white;
}
.box:last-child {
  border-radius: 0 0 6px 6px;
}
.box:hover {
  background: rgb(251, 251, 251);
}
.name {
  font-weight: 400;
  font-size: 14px;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.date {
  font-weight: 400;
  font-size: 14px;
}
.link {
  max-width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #1976d2;
  cursor: pointer;
}
.stats {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  gap: 5px;
  font-size: 14px;
  color: #1976d2;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.textarea {
  position: absolute;
  top: -500px;
  opacity: 0;
}