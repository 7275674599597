.box {
  margin-top: 40px;
  width: 350px;
}
.box__fixed {
  position: fixed;
}
.form {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.title {
  height: 30px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 1.4rem;
  box-sizing: border-box;
}
.input {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #d0d7de;
  background: #ffffff;
  padding: 0 15px;
  box-sizing: border-box;
  transition: all ease 0.2s;
  color: rgb(70, 70, 70);
}
.input:focus {
  outline: none !important;
  border: 1px solid #496ae283;
}
.input:disabled {
  background: #e0e0e0;
  color: rgb(179, 177, 177);
}

.checkbox {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.checkboxText {
  color: rgb(124, 124, 124);
  font-size: 14px;
  user-select: none;
}
.checkboxTextActive {
  color: rgb(29, 29, 29);
  font-size: 14px;
  user-select: none;
}

.button {
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 0 25px;
  background: #1976d2;
  cursor: pointer;
  color: white;
  transition: all ease 0.2s;
  font-weight: 500;
  user-select: none;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background: #5c75e4;
}
.button:disabled {
  background: #8a99dd;
  cursor: default;
}