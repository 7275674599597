@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
}
a:active,
a:hover,
a {
  text-decoration: none;
  color: #000;
}
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  background: #F1F1F1;
  overflow: hidden;
}

.loader__box {
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}