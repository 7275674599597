.box {
  padding: 40px 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.heading {
  overflow: hidden;
  width: 100%;
  height: 100px;
  border-radius: 6px 6px 0 0;
  border: 1px solid #EBEBEB;
  border-bottom: none;
  box-sizing: border-box;
  background: white;
}
.title {
  height: 50px;
  background: #f7f7f7;
  display: grid;
  grid-template-columns: 1.4fr 1.4fr 0.7fr 0.5fr;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  font-size: 14px;
  border-bottom: 2px solid #EBEBEB;
  font-weight: 500;
  color: gray;
  user-select: none;
}
.title__click {
  justify-content: flex-end;
}
.title__btn {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.title__btn:hover {
  color: black;
}

.links__box {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
}