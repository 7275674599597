.box {
  box-sizing: border-box;
  display: flex;
  height: 50px;
  align-items: flex-end;
  gap: 15px;
  padding: 0 10px;
  border-bottom: 2px solid #EBEBEB;
}
.item {
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 15px;
  height: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: gray;
}
.item:hover {
  color: #1976d2;
}

.active {
  color: black;
}
.active:hover {
  color: black;
}
.active:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 10px;
  z-index: 100;
  bottom: -1px;
  left: 0;
  border-bottom: 2px solid #1976d2;
}
